<template>
  <el-dialog v-if="dataInfo" :title="title" @closed="_closed" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false">
    <el-form ref="dataInfo" :model="dataInfo" :rules="dataRules" label-width="120px">
      <el-form-item class="flex-sub" label="角色名称" prop="roleName">
        <el-input v-model="dataInfo.roleName" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item class="flex-sub" label="备注信息">
        <el-input type="textarea" rows="8" placeholder="请输入内容" v-model="dataInfo.roleDesc"> </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      dataRules: {
        roleName: [{ required: true, trigger: 'blur', message: '角色名称不能为空' }]
      }
    }
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    },
    save() {
      this.$refs.dataInfo.validate(async valid => {
        if (valid) {
          await this.$lgh.post('api/enterprise/role/addoredit', this.dataInfo)
          this.visible = false
          this.onsuccess && this.onsuccess(this.dataInfo)
        }
      })
    }
  }
}
</script>

<style></style>
